import api from './api';

const endpoint = 'section_banners';
export default {
  add(params) {
    return api.post(endpoint, params);
  },
  async get(id) {
    const res = await api.fetch(`${endpoint}/${id}`);
    return res;
  },
  async list(params) {
    const res = await api.fetch(endpoint, params);
    return res;
  },
  update(params) {
    return api.patch(endpoint, params);
  },
  delete(id) {
    return api.remove(endpoint, id);
  },
};
