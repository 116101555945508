
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '~/store';
import { HeaderLayout } from '~/store/options';
import Search from '~/components/header/search.vue';
import NavPanel from '~/components/header/nav-panel.vue';
import Topbar from '~/components/header/topbar.vue';
import AppLink from '~/components/shared/app-link.vue';
import LogoSvg from '~/svg/logo.svg';
import Dropdown from '~/components/header/dropdown.vue';

@Component({
  components: { AppLink, NavPanel, Search, Topbar, LogoSvg, Dropdown },
})
export default class Header extends Vue {
  imgUrl = require('@/assets/images/logo.png');
  @State((state: RootState) => state.options.headerLayout)
  layout!: HeaderLayout;

  get getFrontendURL() {
    return this.$config.frontend;
  }
}
