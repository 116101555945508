import api from './api';

import CategoryFilterBuilder from '~/filters/category';
import CheckFilterBuilder from '~/filters/check';
import ColorFilterBuilder from '~/filters/color';
import RadioFilterBuilder from '~/filters/radio';
import RangeFilterBuilder from '~/filters/range';

export default {
  add(product) {
    return api.post('product', product);
  },
  async get(id) {
    const res = await api.fetch(`product/${id}`);
    return res;
  },
  async list(params) {
    const res = await api.fetch('product', params);
    return res;
  },
  update(product) {
    return api.patch('product', product);
  },
  delete(id) {
    return api.remove('product', id);
  },

  async GetProductsList(options, filterValues) {
    const { category, price, brand, discount, color } = filterValues;
    const filters = [
      new CategoryFilterBuilder('category', 'Categories'),
      new RangeFilterBuilder('price', 'Price'),
      // new CheckFilterBuilder('brand', 'Brand'),
      // new RadioFilterBuilder('discount', 'Discount'),
      // new ColorFilterBuilder('color', 'Color'),
    ];

    const itemFilter = (data) => {
      return data.filter((product) => {
        return category ? product.category_id == +category : product;
      });
    };
    let items = await this.list({
      status: 'O',
      page: { size: 1000000 },
      filterExt: 'product_type_id=1',
    }).then(({ data }) => {
      return data;
    });

    // Apply filters to products list.
    // items = items.filter(product => filters.reduce<boolean>((mr, filter) => mr && filter.test(product), true))

    items = itemFilter(items);
    const attributes = items;

    if (price) {
      const [min, max] = price.split('-');
      items = items.filter((product) => {
        return product.price >= min && product.price <= max;
      });
    }

    if (brand) {
      const brands = brand.split(',');
      items = items.filter((product) => {
        return brands.includes(product.brand);
      });
    }

    if (discount) {
    }

    if (color) {
    }

    filters.forEach((filter) =>
      filter.makeItems(attributes, filterValues[filter.slug])
    );

    // Calculate items count for filter values.
    filters.forEach((filter) => filter.calc());

    const page = options.page || 1;
    const limit = options.limit || 12;
    const sort = options.sort || 'default';
    const total = items.length;
    const pages = Math.ceil(total / limit);
    const from = (page - 1) * limit + 1;
    const to = Math.max(Math.min(page * limit, total), from);

    items = items.sort((a, b) => {
      if (['name_asc', 'name_desc'].includes(sort)) {
        if (a.name === b.name) {
          return 0;
        }

        return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
      }

      return 0;
    });

    const start = (page - 1) * limit;
    const end = start + limit;

    items = items.slice(start, end);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          page,
          limit,
          sort,
          total,
          pages,
          from,
          to,
          items,
          filters: filters.map((x) => x.build()),
        });
      }, 350);
    });
  },
};
