import api from './api';

const endpoint = 'cart_headers';
export default {
  add(cart_header) {
    return api.post(endpoint, cart_header);
  },
  async get(id) {
    const res = await api.fetch(`${endpoint}/${id}`);
    return res;
  },
  async list(params) {
    const res = await api.fetch(endpoint, params);
    return res;
  },
  update(cart_header) {
    return api.patch(endpoint, cart_header);
  },
  delete(id) {
    return api.remove(endpoint, id);
  },
  async getByUser() {
    const res = await api.fetch(`${endpoint}/getcartbyusers`);
    return res;
  },
  hitpay(body: any) {
    body.redirect_url =
      'http://localhost:3001/shop/cart?puchased_status=success';

    return api.request({
      url: `${endpoint}/hitpay`,
      method: 'POST',
      body,
      type: 'cart-headers-hitpays',
    });
  },
};
