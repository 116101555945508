import categoryApi from '../api/category'

function makeState () {
    return {
        categoryId: null,
        category: {},
        categories: {},
        children: [],
        options: {},
        filters: {},
        query: ''
    }
}

export const state = makeState

export const mutations = {
    init (state, payload) {
        Object.assign(state, makeState(), {
            categoryId: payload.categoryId,
            options: payload.options,
            filters: payload.filters
        })
    },
    SET_CATEGORIES (state, categories) {
        state.categories = categories
    },
    SET_CATEGORY (state, category) {
        state.category = category
    },
    SET_CATEGORYCHILDREN (state, children) {
        state.children = children
    }
}

export const getters = {
    fetchCategories (state) {
        return state.categories
    },
    fetchCategory (state) {
        return state.category
    }
}

export const actions = {
    async init ({ dispatch, commit }, payload) {
        commit('init', payload)

        await Promise.all([ dispatch('fetchCategory', payload.categoryId) ])
    },
    async fetchCategories ({ commit }, params) {
        try {
            const { data } = await categoryApi.list(params)
            if (data) {
                data.forEach((e) => { e.children = e.children?.data });
                commit('SET_CATEGORIES', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    },
    async fetchCategory ({ commit }, id) {
        try {
            const { data } = await categoryApi.get(id)
            if (data) {
                commit('SET_CATEGORY', data)
                return data
            }
        } catch (err) {
            return Promise.reject(err)
        }
    },
    async fetchCategoryChild ({ commit }, params) {
        try {
            const { data } = await categoryApi.list(params)
            if (data) {
                const parent = data.find((x) => { return +x.id == params.id })
                parent.children?.data.forEach((e) => {
                    delete e.children
                    e.parent = data.find((y) => { return +y.id == e.header_id })
                });
                const children = parent.children?.data

                commit('SET_CATEGORYCHILDREN', children)
                return children
            }
        } catch (err) {
            return Promise.reject(err)
        }
    }
}