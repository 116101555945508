const dataHeaderNavigation: any = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Shop',
    url: '/shop/catalog',
  },
  {
    name: 'Contact Us',
    url: 'https://dhome.solutions/page-contact',
  },
];

export default dataHeaderNavigation;
