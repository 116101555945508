
import { Vue, Component } from "vue-property-decorator";
import FooterContacts from "~/components/footer/footer-contacts.vue";
import FooterLinks from "~/components/footer/footer-links.vue";
import FooterNewsletter from "~/components/footer/footer-newsletter.vue";
import ToTop from "~/components/footer/to-top.vue";
import theme from "~/data/theme";

@Component({
  components: { ToTop, FooterNewsletter, FooterLinks, FooterContacts },
  computed: {
    myAccountLinks: {
      get() {
        return [
          {
            title: "Dashboard",
            url: "/",
          },
          {
            title: "Order History",
            url: `${process.env.URL}/dashboard/myorders/`,
          },
          {
            title: "Wish List",
            url: "",
          },
          {
            title: "Edit Profile",
            url: `${process.env.URL}/dashboard/account/update-profile/`,
          },
          { title: "Addresses", url: "" },
          // {title: 'Password', url: ''},
        ];
      },
      set(v) {
        //
      },
    },
    informationLinks: {
      get() {
        return [
          { title: "About Us", url: "https://dhome.solutions/page-about" },
          // { title: "Brands", url: "" },
          // { title: "Delivery Information", url: "" },
          { title: "Terms & Conditions", url: "https://dhome.solutions/page-tc" },
          // { title: "Privacy Policy", url: "" },
        ];
      },
      set(v) {
        //
      },
    },
  },
})
export default class Footer extends Vue {
  theme = theme;
}
