var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'search',
    `search--location--${_vm.location}`,
    {
      'search--has-suggestions': _vm.hasSuggestions,
      'search--suggestions-open': _vm.suggestionsOpen,
    },
  ]},[_c('div',{staticClass:"search__body"},[_c('form',{staticClass:"search__form"},[(_vm.location === 'header')?_c('select',{staticClass:"search__categories",attrs:{"aria-label":"Category"},on:{"change":_vm.onCategoryChange,"focus":_vm.onCategoryFocus}},[_c('option',{attrs:{"value":""}},[_vm._v("All Categories")]),_vm._v(" "),_vm._l((_vm.categories),function(category){return _c('option',{key:category.id,domProps:{"value":category.name}},[_vm._v("\n          "+_vm._s(category.name)+"\n          ")])})],2):_vm._e(),_vm._v(" "),_c('input',{ref:"input",staticClass:"search__input",attrs:{"name":"search","placeholder":"Search over 10,000 products","aria-label":"Site search","type":"text","autocomplete":"off"},domProps:{"value":_vm.query},on:{"input":_vm.onQueryInput,"focus":_vm.onQueryFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onQueryEscape.apply(null, arguments)}}}),_vm._v(" "),_c('button',{staticClass:"search__button search__button--type--submit",attrs:{"type":"submit"}},[_c('Search20Svg')],1),_vm._v(" "),(_vm.location === 'mobile-header')?_c('button',{staticClass:"search__button search__button--type--close",attrs:{"type":"button"},on:{"click":_vm.onCloseClick}},[_c('Cross20Svg')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"search__border"})]),_vm._v(" "),_c('div',{class:[
        'search__suggestions',
        'suggestions',
        `suggestions--location--${_vm.location}`,
      ]},[_c('ul',{staticClass:"suggestions__list"},_vm._l((_vm.suggestedProducts),function(product){return _c('li',{key:product.id,staticClass:"suggestions__item"},[_c('div',{staticClass:"suggestions__item-image product-image"},[_c('div',{staticClass:"product-image__body"},[_c('img',{staticClass:"product-image__img",attrs:{"src":_vm.onGetImage(product),"alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"suggestions__item-info"},[_c('NuxtLink',{staticClass:"suggestions__item-name",attrs:{"to":_vm.$url.product(product)},nativeOn:{"click":function($event){return _vm.closeSuggestion()}}},[_vm._v("\n              "+_vm._s(product.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"suggestions__item-meta"},[_vm._v("SKU: "+_vm._s(product.sku))])],1),_vm._v(" "),(product.new_price > 0)?_c('div',{staticClass:"suggestions__item-price"},[_vm._v("\n            "+_vm._s(_vm.$price(product.new_price))+"\n          ")]):_c('div',{staticClass:"suggestions__item-price"},[_vm._v("\n            "+_vm._s(_vm.$price(product.price))+"\n          ")]),_vm._v(" "),(_vm.location === 'header')?_c('div',{staticClass:"suggestions__item-actions"},[_c('AsyncAction',{attrs:{"action":() => _vm.$store.dispatch('cart/add', { product })},scopedSlots:_vm._u([{key:"default",fn:function({ run, isLoading }){return [_c('button',{class:[
                  'btn btn-primary btn-sm btn-svg-icon',
                  { 'btn-loading': isLoading },
                ],attrs:{"type":"button","title":"Add to cart"},on:{"click":run}},[_c('Cart16Svg')],1)]}}],null,true)})],1):_vm._e()])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }